<template>
  <b-card>
    <b-form>
      <div class="flex items-center gap-3">
        <b-form-group label="Tìm kiếm" style="flex: 1">
          <b-form-input
            v-model="filters.keyword"
            debounce="500"
            placeholder="Nhập tên chương trình"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Thời hạn sử dụng" style="flex: 1">
          <b-form-select v-model="filters.duration" :options="durationOptions"></b-form-select>
        </b-form-group>
        <b-form-group label="Thời hạn sử dụng cụ thể" style="flex: 2">
          <div class="flex items-center gap-3">
            <b-form-datepicker
              placeholder="Chọn ngày bắt đầu"
              v-model="filters.from_date"
              :locale="$i18n.locale"
              v-bind="$getCalendarLabel($i18n.locale)"
            ></b-form-datepicker>
            <b-form-datepicker
              placeholder="Chọn ngày kết thúc"
              v-model="filters.to_date"
              :locale="$i18n.locale"
              v-bind="$getCalendarLabel($i18n.locale)"
            ></b-form-datepicker>
          </div>
        </b-form-group>
      </div>
    </b-form>
    <div class="flex justify-between mt-1">
      <b-button variant="outline-primary" @click="handleResetFilter">Làm mới bộ lọc</b-button>
      <b-button variant="primary" class="w-fit" @click="handleCreate">Tạo mới chương trình</b-button>
    </div>
    <b-table
      :busy="isLoading"
      striped
      hover
      :items="dataTable"
      :fields="fieldsTable"
      empty-text="Không có dữ liệu"
      class="mt-2 mb-3"
      ref="refDataTable"
    >
      <template #table-busy>
        <div class="text-center my-2 flex flex-col gap-2 justify-center">
          <div class="text-center">
            <b-spinner class="align-middle"></b-spinner>
          </div>
          <strong>Đang tải</strong>
        </div>
      </template>
      <template
        #cell(index)="data"
      >{{ (pagination.currentPage - 1) * pagination.perPage + data.index + 1 }}</template>
      <template #cell(point)="data">{{ appUtils.numberFormat(data.item.point) }}</template>
      <!-- <template #cell(type)="data">{{ getHodoMembership(data.item.type) }}</template> -->
      <template #cell(start_date)="data">{{ appUtils.formatDate(new Date(data.item.start_date)) }}</template>
      <template
        #cell(expired_date)="data"
      >{{ appUtils.formatDate(new Date(data.item.expired_date)) }}</template>
    </b-table>
    <Pagination
      class="mx-0"
      :meta="metaData"
      :perPage="pagination.perPage"
      :currentPage="pagination.currentPage"
      :totals="pagination.totals"
      @setCurrentPage="handleSetCurrentPage"
      @setPerPage="handleSetPerPage"
    />
  </b-card>
</template>
<script>
import Pagination from '@/layouts/components/Pagination.vue'
import appUtils from '../../../utils/appUtils'
import { HODO_CREDIT_TYPE, HODO_MEMBERSHIP } from '@/utils/constant'
export default {
  name: 'SpendPoint',
  components: { Pagination },
  data() {
    return {
      filters: {
        keyword: '',
        duration: '',
        from_date: '',
        to_date: ''
      },
      durationOptions: [
        { value: '', text: 'Chọn thời hạn sử dụng' },
        { value: 1, text: '6 tháng kể từ ngày kích hoạt ví' },
        { value: 2, text: '12 tháng kể từ ngày kích hoạt ví' },
        { value: 3, text: '24 tháng kể từ ngày kích hoạt ví' }
      ],
      fieldsTable: [
        {
          key: 'index',
          label: 'STT'
        },
        {
          key: 'name',
          label: 'Tên chương trình'
        },
        {
          key: 'create_by_info.name',
          label: 'Người thực hiện'
        },
        // {
        //   key: 'type',
        //   label: 'HODO Membership'
        // },
        {
          key: 'start_date',
          label: 'Thời gian bắt đầu'
        },
        {
          key: 'expired_date',
          label: 'Thời gian kết thúc'
        }
      ],
      dataTable: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        totals: 0
      },
      isLoading: false,
      appUtils
    }
  },
  mounted() {
    this.handleGetDeposit()
  },
  computed: {
    metaData() {
      const localItemsCount = this.$refs?.refDataTable
        ? this.$refs?.refDataTable.localItems?.length
        : 0
      return {
        from:
          this.pagination.perPage * (this.pagination.currentPage - 1) +
          (localItemsCount ? 1 : 0),
        to:
          this.pagination.perPage * (this.pagination.currentPage - 1) +
          localItemsCount,
        of: this.pagination.totals
      }
    }
  },
  watch: {
    'filters.keyword': {
      handler() {
        this.pagination.currentPage = 1
        this.handleGetDeposit()
      }
    }
  },
  methods: {
    handleSetCurrentPage(value) {
      this.pagination.currentPage = value
      this.handleGetDeposit()
    },
    handleSetPerPage(value) {
      this.pagination.perPage = value
      this.pagination.currentPage = 1
      this.handleGetDeposit()
    },
    handleCreate() {
      this.$router.push({ name: 'CreateSpendPointTransaction' })
    },
    handleResetFilter() {
      this.filters = {
        keyword: '',
        duration: '',
        from_date: '',
        to_date: ''
      }
      this.handleGetDeposit()
    },
    async handleGetDeposit() {
      this.isLoading = true
      const userData = appUtils.getLocalUser()

      if (!userData.supplierInfo?.org_id) return
      try {
        const params = {
          page_num: this.pagination.currentPage,
          page_size: this.pagination.perPage,
          org_id: userData.supplierInfo?.org_id,
          type: HODO_CREDIT_TYPE.redemption,
          sort_by: 'created_at',
          order: 'desc',
          keyword: this.filters.keyword,
          created_at: this.filters.created_at
        }

        const response = await this.$rf
          .getRequest('CommonRequest')
          .getListPointProgram(params)

        this.dataTable = response.data?.data || []
        this.pagination.totals = response.data?.page.total
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }
    },
    getHodoMembership(type) {
      return HODO_MEMBERSHIP.find(elm => elm.value === type)?.text
    }
  }
}
</script>
<style lang="scss" scoped>
.container-credit {
  background: #fff;
}
</style>